import Link from 'next/link'
import { useEffect, useState } from 'react'

const ShowOff = ({ data }) => {
	const [mapData, setMapData] = useState(
		data?.length ? data.map((e) => ({ ...e, value: 0 })) : data
	)
	const [isFirst, setIsFirst] = useState(false)
	useEffect(() => {
		if (!mapData?.length) return

		setIsFirst(true)

		if (isFirst) return

		const time = 2400
		const intervalIds = []

		data.forEach((e, index) => {
			if (e.value <= 0) return

			let freq = time / e.value
			if (e.value == 1) freq = 1000

			let currentValue = 0
			let intervalId = setInterval(() => {
				if (currentValue >= e.value) return
				currentValue += e.value > 100 ? 100 : 2
				setMapData((prev) => {
					const dup = [...prev]
					dup[index] = {
						...prev[index],
						value: currentValue <= data[index].value ? currentValue : data[index].value,
					}

					return dup
				})
			}, freq)

			intervalIds.push(intervalId)
		})
	}, [mapData, isFirst, data])

	return (
		<div className="c-show-off">
			{mapData.map((s, index) => (
				<div key={s.id} className="show-item">
					<Link href={s.url}>
						<span className="item-value">
							{s.value}
							{index == 3 ? '%' : ''}
						</span>
					</Link>
					<span className="item-key">{s.key}</span>
				</div>
			))}
		</div>
	)
}

export default ShowOff
