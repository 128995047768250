export function validateEmail(email) {
	var re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(email)
}

export function validatePhone(phone) {
	var tele =
		/^(((\+?84|0)?(86|54|96|77|87|55|89|52|97|98|87|32|33|34|35|36|37|38|39|89|90|69|93|70|79|77|76|78|88|91|94|83|84|85|81|82|92|56|58|99|59)))[0-9]{7}$|^(0((((28|24|77)[0-9])|(290|291|299|293|297|292|296|270|294|275|277|273|272|254|251|276|274|271|252|263|259|261|262|257|269|256|260|255|235|236|234|233|232|239|238|237|229|228|227|226|221|225|220|222|211|210|218|212|215|213|214|207|216|209|219|203|204|205|206))))[0-9]{7}$/
	return tele.test(phone)
}

// validate string theo format email hoặc sdt
export function validateIsMailOrPhone(string) {
	return validateEmail(string) || validatePhone(string)
}

// Xóa dấu câu, kí tự tiếng việt (tối ưu tính năng tìm kiếm ở các component Input)
export function rmVieTones(str) {
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
	str = str.replace(/đ/g, 'd')
	str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
	str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
	str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
	str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
	str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
	str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
	str = str.replace(/Đ/g, 'D')
	return str
}
export function isValidTaxCode(input) {
	var isValid = false

	if (10 == input.length) {
		isValid = isValidFirstTenNumberOfTaxCode(input)
	} else if (14 == input.length) {
		var firstTenNumbers = input.substring(0, 10)
		var minusSign = input.substring(10, 11)
		var lastThreeNumbers = input.substring(11, 14)

		if (!isNaN(firstTenNumbers) && !isNaN(lastThreeNumbers) && '-' == minusSign) {
			isValid = isValidFirstTenNumberOfTaxCode(firstTenNumbers)
		}
	}
	return isValid
}
function isValidFirstTenNumberOfTaxCode(taxCode) {
	var n1 = taxCode.substring(0, 1)
	var n2 = taxCode.substring(1, 2)
	var n3 = taxCode.substring(2, 3)
	var n4 = taxCode.substring(3, 4)
	var n5 = taxCode.substring(4, 5)
	var n6 = taxCode.substring(5, 6)
	var n7 = taxCode.substring(6, 7)
	var n8 = taxCode.substring(7, 8)
	var n9 = taxCode.substring(8, 9)
	var n10 = taxCode.substring(9, 10)

	if (
		n10 !=
		10 -
			((n1 * 31 + n2 * 29 + n3 * 23 + n4 * 19 + n5 * 17 + n6 * 13 + n7 * 7 + n8 * 5 + n9 * 3) % 11)
	) {
		return false
	} else {
		return true
	}
}
