const { useEffect, useState } = require('react')
const { default: addressServices } = require('src/services/address.service')

// Lấy danh sách quận/huyện từ ID tỉnh
const useDistricts = (provinceID) => {
	const [districts, setDistricts] = useState(null)

	useEffect(() => {
		if (!provinceID) return

		addressServices
			.queryDistricts(provinceID)
			.then((response) => {
				if (!response.isError) setDistricts(response.districts)
			})
			.catch(console.log)
	}, [provinceID])

	return districts
}

export default useDistricts
