const { useState } = require('react')

const useLanguages = () => {
	const [currentLanguage, setCurrentLanguage] = useState(null)

	const triggerEvent = (element, eventName) => {
		const event = new Event(eventName)
		element.dispatchEvent(event)
	}

	const toVietnamese = (lang) => {
	}

	return {
		currentLanguage,
		toVietnamese,
	}
}

export default useLanguages
