import { Button, Col, Collapse, Form, Input, Modal, notification, Row } from 'antd'
import { useState } from 'react'
import { DeliverIcon, DeliveryIcon2, FastParcelIcon } from 'src/components/CustomIcon'
import BASE_CONFIG from 'src/configs/base'
import contactService from 'src/services/contact.service'
import { formatNumberWithCommas } from 'src/utils/common'
import { RULE_FORM } from 'src/utils/formValidation'

const { Panel } = Collapse

const PriceItem = ({ data, index }) => (
	<div className="price-item">
		<div className="item-icon">{index == 0 && <FastParcelIcon />}</div>
		<div className="item-icon">{index == 1 && <DeliverIcon />}</div>
		<div className="item-icon">{(index == 2 || index ==3) && <DeliveryIcon2 />}</div>
		<div className="item-name">
			<span className="name-title">{data.serviceTypeName}</span>
			{/* <span className="name-desc">
				Thời gian dự kiến {moment(data.estimatedDateOfReceipt).format('DD/MM/YYYY')}
			</span> */}
		</div>
		<div className="item-price">
			<span className="price-mb__label" style={{ display: 'none' }}>
				Tổng giá:
			</span>
			<span className="price-amount">{formatNumberWithCommas(data.totalFee)} VND</span>
		</div>
	</div>
)

const PriceModal = ({ data, visibled, onClose }) => {
	const [isContact, setIsContact] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)

	const handleClose = () => {
		setIsSuccess(false)
		setIsContact(false)
		onClose()
	}

	const handleSubmitContact = (values) => {
		values.content = '[Lấy thông tin báo giá]'
		contactService
			.insertPriceContact(values)
			.then((response) => {
				if (!response.IsError) {
					setIsSuccess(true)
				}
			})
			.catch(() => {
				handleClose()
				notification.error({
					message: 'Lỗi!',
					description: 'Có lỗi xảy ra, vui lòng thử lại sau!',
				})
			})
	}

	const ContactForm = (
		<div className="price__modal-contact__form">
			{/* eslint-disable-next-line @next/next/no-img-element */}
			<img src="/images/contactform_img.png" alt="Liên hệ" />
			<div className="contact__form-form">
				<p className="description">
					Vui lòng để lại thông tin để nhân viên{' '}
					<span style={{ color: 'var(--my-red)' }}>247Express</span> có thể liên hệ tới bạn sớm nhất
				</p>
				<Form onFinish={handleSubmitContact} scrollToFirstError>
					<Form.Item
						rules={[
							{ required: true, message: 'Vui lòng nhập tên' },
							{ whitespace: true, message: 'Vui lòng nhập tên' },
						]}
						style={{ marginTop: 12 }}
						name="name"
					>
						<Input placeholder="Họ và Tên" />
					</Form.Item>
					<Form.Item rules={RULE_FORM.PHONE_NUMBER} style={{ marginTop: 12 }} name="phone">
						<Input placeholder="Số điện thoại" />
					</Form.Item>
					<Form.Item
						rules={[{ type: 'email', message: 'Email không đúng định dạng!' }]}
						style={{ marginTop: 12 }}
						name="email"
					>
						<Input placeholder="Email" />
					</Form.Item>
					<Form.Item
						rules={[{ required: true, message: 'Vui lòng nhập địa chỉ chi tiết!' }]}
						style={{ marginTop: 12 }}
						name="Address"
					>
						<Input placeholder="Địa chỉ chi tiết" />
					</Form.Item>
					<Row gutter={16} style={{ marginTop: 32 }} className="form-btns">
						<Col span={12}>
							<Button
								style={{
									width: '100%',
									height: 44,
									fontSize: 16,
									lineHeight: '24px',
									border: '1px solid var(--my-red)',
								}}
								htmlType="submit"
								onClick={handleClose}
							>
								Hủy
							</Button>
						</Col>
						<Col span={12}>
							<Button
								style={{ width: '100%', height: 44, fontSize: 16, lineHeight: '24px' }}
								type="primary"
								htmlType="submit"
							>
								Xác nhận
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	)
	const ContactSuccess = (
		<div className="price__modal-success">
			{/* eslint-disable-next-line @next/next/no-img-element */}
			<img src="/images/contact_success.png" alt="Liên hệ thành công" />
			<h4 style={{ textAlign: 'center' }}>Hệ thống đã cập nhật thông tin thành công!</h4>
			<p style={{ color: '#5E6278', textAlign: 'center' }}>
				Nhân viên <strong style={{ color: 'var(--my-red)' }}>{BASE_CONFIG.PAGE_NAME}</strong> sẽ
				liên hệ đến bạn trong thời gian sớm nhất
			</p>
		</div>
	)
	const PriceFooter = (
		<div className="price__modal-footer">
			<span style={{ textAlign: 'left' }}>Liên hệ với chúng tôi để được báo giá tốt hơn</span>
			<Button onClick={() => setIsContact(true)} type="primary">
				Liên hệ
			</Button>
		</div>
	)

	const PriceData = (
		<div className="price__modal-container">
			<Collapse expandIconPosition="end">
				{data?.map((e, index) => (
					<Panel header={<PriceItem data={e} index={index} />} key={index}>
						<div className="price__item-detail">
							<div className="detail-field">
								<span className="field-key">Cước chính</span>
								<span className="field-value">{formatNumberWithCommas(e.price) || 0}đ</span>
							</div>
							<div className="detail-field">
								<span className="field-key">Phí nhiên liệu</span>
								<span className="field-value">{formatNumberWithCommas(e.fuelFee) || 0}đ</span>
							</div>
							<div className="detail-field">
								<span className="field-key">Phí VAT</span>
								<span className="field-value">{formatNumberWithCommas(e.vatFee) || 0}đ</span>
							</div>
							<div className="detail-field">
								<span className="field-key">Phí ngoại thành</span>
								<span className="field-value">{formatNumberWithCommas(e.farFee) || 0}đ</span>
							</div>
							<div className="detail-field">
								<span className="field-key">Phí dịch vụ gia tăng</span>
								<span className="field-value">
									{formatNumberWithCommas(e.totalServiceFee) || 0}đ
								</span>
							</div>
						</div>
					</Panel>
				))}
			</Collapse>
		</div>
	)

	const currentContent = isContact ? (isSuccess ? ContactSuccess : ContactForm) : PriceData

	const currentTitle = isContact ? (
		isSuccess ? (
			<strong>Cảm ơn quý khách!</strong>
		) : (
			'Liên hệ'
		)
	) : (
		'Tính giá tham khảo'
	)

	return (
		<div className="price__modal">
			<Modal
				destroyOnClose
				open={true}
				centered
				getContainer=".price__modal"
				width={600}
				title={
					<div
						style={{
							color: '#181C32',
							textAlign: 'center',
							fontSize: 20,
							lineHeight: '32px',
							fontWeight: 600,
						}}
					>
						{currentTitle}
					</div>
				}
				footer={!isContact && PriceFooter}
				onCancel={handleClose}
				style={{
					borderRadius: 16,
					overflow: 'hidden',
				}}
			>
				{currentContent}
			</Modal>
		</div>
	)
}

export default PriceModal
