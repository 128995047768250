import { DownOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { CollapseIcon, CollapseIconActive } from 'src/components/CustomIcon'
import BASE_CONFIG from 'src/configs/base'
import MENU_CONFIGS from 'src/configs/menu'
import { preparePath, stripQueryStringAndHashFromPath } from 'src/utils/common'

const MobileMenu = ({ lang, Languages, items }) => {
	const router = useRouter()
	const asPath = preparePath(stripQueryStringAndHashFromPath(router.asPath))

	const MobileMenuItem = ({ data, level = 1 }) => {
		const preparedUrl = preparePath(data.Url)
		const isChildActive = !!data.Children?.find((i) => i.Url == asPath)
		const isTitleActive =
			(preparedUrl.split('/')[level] || {}) == (asPath.split('/')[level] || {}) ||
			preparedUrl == asPath ||
			!!isChildActive
		const [isExpanded, setIsExpanded] = useState(false)
		const isDisabled = MENU_CONFIGS.DISABLED_ITEMS_WHITELIST.includes(data.Url)

		return (
			<li key={data.Url} className={'MobileMenuItem' + (isTitleActive ? ' Active' : '')}>
				{isDisabled ? (
					<span className="mb__disable__item">{data.Name}</span>
				) : (
					<Link href={data.Url}>
						{data.Name}
					</Link>
				)}

				{data.Children?.length > 0 && (
					<>
						{isExpanded || isChildActive ? (
							<>
								<CollapseIconActive onClick={() => setIsExpanded(false)} />
								<ul className="MobileSubMenu">
									{data.Children.map((e) => (
										<MobileMenuItem level={level + 1} key={e.Url} data={e} />
									))}
								</ul>
							</>
						) : (
							<CollapseIcon onClick={() => setIsExpanded(true)} />
						)}
					</>
				)}
			</li>
		)
	}

	return (
		<div className="MobileMenu">
			<div className="MobileMenu-Nav">
				<ul className="MobileMenuList">
					{[{ Name: 'Trang chủ', Url: '/' }, ...items].map((e) => (
						<MobileMenuItem key={e.Url} data={e} />
					))}
				</ul>
			</div>
			<div className="MobileMenu-Divider" />
			<div className="MobileMenu-Lang">
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<img src={lang.icon} alt={lang.label} />
				<Popover style={{ padding: 0 }} content={Languages}>
					<span className="LangValue">
						<span className="LangValue-Label">{lang.value}</span>
						<DownOutlined style={{ marginLeft: 8, fontSize: 12, transform: 'translateY(-1px)' }} />
					</span>
				</Popover>
			</div>
			<div className="MobileMenu-Hotline">
				<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} href={`tel:${BASE_CONFIG.HOTLINE}`}>
					{/* eslint-disable-next-line @next/next/no-img-element */}
					<img src="/icons/hotline.png" alt={BASE_CONFIG.HOTLINE} />
					<span>{BASE_CONFIG.HOTLINE}</span>
				</a>
			</div>
		</div>
	)
}

export default MobileMenu
