import Footer from 'src/components/Footer'
import Header from 'src/components/Header'

export function LandingLayout({ hideBreadcrumb, children }) {
	return (
		<>
			<Header />
			<div>{children}</div>
			<Footer />
		</>
	)
}
