import { Switch } from 'antd';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { ActionNotifIcon, CartNotifIcon, MailIcon, NotifIcon, PushMessageIcon, ServiceNotifIcon, SmsNotifIcon } from 'src/components/CustomIcon';
import * as api from 'src/configs/API';
import request from 'src/utils/request';

const NotifForm = () => {
	const [userJson, setUserJson] = useState(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : "");
	const firstUpdate = useRef(true);

	const [notiSetting, setNotiSetting] = useState({});

	const getNotiSetting = () => {
		request.pmsApi({
			url: api.PMS_APIs.API_CLIENT_GET_NOTI_SETTING,
			data: {
				ClientID: userJson.clientID,
				ClientHubID: userJson.mainHubID
			},
			method: 'POST',
		}).then(res => {
			setNotiSetting(res.settings);
		}).catch(console.log())
	}

	const updateSetting = () => {
		request.pmsApi({
			url: api.PMS_APIs.API_CLIENT_UPDATE_NOTI_SETTING,
			data: {
				ClientID: userJson.clientID,
				ClientHubID: userJson.mainHubID,
				Settings: notiSetting
			},
			method: 'POST',
		}).then(res => {
			getNotiSetting();
		}).catch(console.log())
	}

	const handleUpdateSetting = (type, value) => {
		console.log(value);
		let newData = notiSetting;
		switch (type) {
			case "isActive":
				newData.isActive = value
				break;
			case "isPushNoti":
				newData.isPushNoti = value
				break;
			case "isSendMail":
				newData.isSendMail = value
				break;
			case "isSendSMS":
				newData.isSendSMS = value
				break;
			case "isPushOrdersNoti":
				newData.isPushOrdersNoti = value
				break;
			case "isPushServicesNoti":
				newData.isPushServicesNoti = value
				break;
			case "isPushActivitiesNoti":
				newData.isPushActivitiesNoti = value
				break;
			default:
				break;
		}
		// setNotiSetting(newData);
		request.pmsApi({
			url: api.PMS_APIs.API_CLIENT_UPDATE_NOTI_SETTING,
			data: {
				ClientID: userJson.clientID,
				ClientHubID: userJson.mainHubID,
				Settings: newData
			},
			method: 'POST',
		}).then(res => {
			getNotiSetting();
		}).catch(console.log())
	}

	// useLayoutEffect(() => {
	// 	if (firstUpdate.current) {
	// 		firstUpdate.current = false;
	// 		return;
	// 	}
	// 	updateSetting();
	// }, [notiSetting]);

	useEffect(() => {
		getNotiSetting();
	}, []);

	return (
		<div className="user__options-detail">
			<div className="notif__form">
				<div className="notif-option">
					<span className="option-label">Thông báo về sự kiện</span>
					<div className="option-container">
						<NotifIcon />
						<span className="option-title">Cho phép thông báo trên 247Express</span>
						<Switch checked={notiSetting.isActive} onChange={(e) => handleUpdateSetting("isActive", e)} />
					</div>
				</div>
				<div className="notif-option">
					<span className="option-label">Nơi nhận các thông báo</span>
					<div className="option-container">
						<PushMessageIcon />
						<span className="option-title">Thông báo đẩy</span>
						<Switch checked={notiSetting.isPushNoti} onChange={(e) => handleUpdateSetting("isPushNoti", e)} />
					</div>
					<div className="option-container">
						<MailIcon />
						<span className="option-title">Email</span>
						<Switch checked={notiSetting.isSendMail} onChange={(e) => handleUpdateSetting("isSendMail", e)} />
					</div>
					<div className="option-container">
						<SmsNotifIcon />
						<span className="option-title">SMS</span>
						<Switch checked={notiSetting.isSendSMS} onChange={(e) => handleUpdateSetting("isSendSMS", e)} />
					</div>
				</div>
				<div className="notif-option">
					<span className="option-label">Nhận thông báo về</span>
					<div className="option-container">
						<CartNotifIcon />
						<span className="option-title">Đơn hàng</span>
						<Switch checked={notiSetting.isPushOrdersNoti} onChange={(e) => handleUpdateSetting("isPushOrdersNoti", e)} />
					</div>
					<div className="option-container">
						<ServiceNotifIcon />
						<span className="option-title">Dịch vụ</span>
						<Switch checked={notiSetting.isPushServicesNoti} onChange={(e) => handleUpdateSetting("isPushServicesNoti", e)} />
					</div>
					<div className="option-container">
						<ActionNotifIcon />
						<span className="option-title">Hoạt động</span>
						<Switch checked={notiSetting.isPushActivitiesNoti} onChange={(e) => handleUpdateSetting("isPushActivitiesNoti", e)} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotifForm
