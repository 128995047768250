const BASE_CONFIG = {
	PAGE_SITE: '247express.vn',
	PAGE_URL: 'https://247express.vn',
	PAGE_NAME: '247Express',
	SLOGAN: '247Express - Nhanh hơn bạn nghĩ',
	HOTLINE: '1900 6980',
	YOUTUBE: 'https://www.youtube.com/c/247Expressvn',
	FACEBOOK: 'https://www.facebook.com/247post.vn',
	MAP_KEY: 'AIzaSyA4Y62p1XK0sRmyz-L9sJ8Fy2mkP8KahGs',
	DEFAULT_SEO_SLUG: 'tin-tuc-dac-biet',
	LANGS: [
		{
			value: 'Vie',
			label: 'Tiếng Việt',
			icon: '/icons/vie.png',
		},
		{
			value: 'Eng',
			label: 'English',
			icon: '/icons/eng.png',
		},
	],
}
export default BASE_CONFIG
