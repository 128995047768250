import APIConstants, { V4_APIs } from 'src/configs/API'
import request from 'src/utils/request'
import useSWR from 'swr'

const { delay } = require('src/utils/common')

const getHomeNews = async () => {
	return request.v4Api({
		url: APIConstants.API_GET_HOME_POSTS,
	})
}

const getNews = async (payload) => {
	const data = {
		PageSize: payload?.pageSize || 6,
		PageIndex: payload?.pageIndex || 1,
		SortBy: '_Id',
		OrderBy: 'desc',
		Status: 2,
	}
	if (payload?.categorySlug) data.CategorySlug = payload.categorySlug
	if (payload?.name) data.Name = payload.name

	return request.v4Api({
		method: 'POST',
		url: APIConstants.API_GET_LIST_POST,
		data,
	})
}

const getAllNews = async () => {
	return request.v4Api({
		url: APIConstants.API_GET_ALL_POST,
		method: 'POST',
		data: {
			Status: 2,
		},
	})
}

const getNewsByCategory = async () => {
	await delay(500)

	return news
}

const getNewsCategories = async (payload) => {
	return request.v4Api({
		url: APIConstants.API_GET_LIST_CATEGOTY_POST,
		method: 'POST',
		data: {
			PageSize: 100,
			PageIndex: 0,
			SortBy: 'desc',
			OrderBy: 'Id',
			Status: 2,
		},
	})
}

const getNewBySlug = async (slug) => {
	return request.v4Api({
		url: APIConstants.API_GET_POST_BY_SLUG + slug,
	})
}

const getNewById = async (id, slug, categorySlug) => {
	return request.v4Api({
		url: APIConstants.API_GET_POST_BY_ID_AND_SLUG + categorySlug + '/' + slug + '/' + id,
	})
}

const getPinPost = async () => {
	return request.v4Api({
		url: V4_APIs.GET_PIN_POST,
	})
}

const getTopNews = async () => {
	const data = {
		PageSize: 5,
		PageIndex: 1,
		SortBy: 'ViewCount',
		OrderBy: 'desc',
		Status: 2,
	}

	return request.v4Api({
		method: 'POST',
		url: APIConstants.API_GET_LIST_POST,
		data,
	})
}

const usePinPost = () => {
	const { data, error } = useSWR('PIN_POST', getPinPost)

	return {
		pinPost: data,
		isLoading: !error && !data,
		isError: error,
	}
}

const searchPosts = (q) => {
	return request.v4Api({
		url: V4_APIs.SEARCH_POSTS,
		method: 'POST',
		data: {
			Keyword: q,
		},
	})
}

const newsServices = {
	getNews,
	getHomeNews,
	getNewsCategories,
	getNewsByCategory,
	getNewBySlug,
	getAllNews,
	getNewById,
	usePinPost,
	getTopNews,
	searchPosts,
}

export default newsServices
