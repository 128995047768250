import { CloseOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, notification, Row, Select, Tag } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import useDistricts from 'src/hooks/useDistricts'
import useWards from 'src/hooks/useWards'
import addressService from 'src/services/address.service'
import commonService from 'src/services/common.service'
import extraServiceServices from 'src/services/extraService.service'
import { rmVieTones } from 'src/utils/validation'
import ExtraServiceCheckbox from './ExtraServiceCheckbox'
import PriceModal from './PriceModal'
const { Option } = Select

const PriceTab = ({ isActive }) => {
	const { cities } = addressService.usePMSCities()

	const [loading, setLoading] = useState(false)

	const [prices, setPrices] = useState(null)
	const [priceModalVisibled, setPriceModalVisibled] = useState(false)

	const [selectedFromCity, setSelectedFromCity] = useState(null)
	const [selectedToCity, setSelectedToCity] = useState(null)
	const [selectedFromDistrict, setSelectedFromDistrict] = useState(null)
	const [selectedToDistrict, setSelectedToDistrict] = useState(null)

	const fromDistricts = useDistricts(selectedFromCity)
	const toDistricts = useDistricts(selectedToCity)
	const fromWards = useWards(selectedFromDistrict)
	const toWards = useWards(selectedToDistrict)

	const [extraServiceCheckbox, setExtraServiceCheckbox] = useState(false)
	const [extraServices, setExtraServices] = useState([])
	const [selectedExtraServices, setSelectedExtraServices] = useState([])
	const [displaySelectedExtraServices, setDisplaySelectedExtraServices] = useState([])

	const toggleExtraServiceCheckbox = (status) => {
		setExtraServiceCheckbox(!!status)
	}

	const handleFromCityChange = (value) => {
		setSelectedFromCity(value)
		setSelectedFromDistrict(null)
	}

	const handleToCityChange = (value) => {
		setSelectedToCity(value)
		setSelectedToDistrict(null)
	}

	const handleCloseModal = () => {
		setPriceModalVisibled(false)
		setPrices(null)
	}

	const handleExtraServiceChange = (s) => {
		setSelectedExtraServices(s)
	}

	const handleSelectExtraService = () => {
		setDisplaySelectedExtraServices(selectedExtraServices)
	}

	const handleRemoveExtraService = (v) => {
		setSelectedExtraServices((prev) => prev.filter((e) => e != v))
		setDisplaySelectedExtraServices((prev) => prev.filter((e) => e != v))
	}

	const handleSubmit = (values) => {
		setLoading(true)

		if (!selectedFromCity || !selectedToCity || !selectedFromDistrict || !selectedToDistrict) {
			setLoading(false)
			return notification.warn({
				message: 'Không thể thực hiện',
				description: 'Vui lòng nhập đầy đủ địa chỉ!',
			})
		}

		const payload = {
			fromProvinceId: selectedFromCity,
			fromDistrictId: selectedFromDistrict,
			fromWardId: fromWards[0]?.wardID,
			toProvinceId: selectedToCity,
			toDistrictId: selectedToDistrict,
			toWardId: toWards[0]?.wardID,
			weight: values.weight,
			length: Number(values.Length),
			width: Number(values.Width),
			height: Number(values.Height),
			quantity: Number(values.Quantity),
			services: displaySelectedExtraServices,
			acceptTime: new Date().toISOString(),
		}

		commonService
			.calcPrice(payload)
			.then((response) => {
				if (!response.isError) {
					setPrices(response.prices)
					setPriceModalVisibled(true)
				} else {
					throw new Error(response.errorMessage)
				}
			})
			.catch((e) => {
				notification.error({
					message: 'Có lỗi xảy ra!',
					description: e.message,
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const filterOption = useCallback((input, option) => {
		return rmVieTones(option.children).toLowerCase().includes(rmVieTones(input).toLowerCase())
	}, [])

	const handleCancelExtraService = () => {
		toggleExtraServiceCheckbox(false)
		setSelectedExtraServices([...displaySelectedExtraServices])
	}

	const ExtraServiceFooter = [
		<Button onClick={handleCancelExtraService} className="ext-footer__btns cancel" key="cancel">
			Hủy
		</Button>,
		<Button
			onClick={() => {
				toggleExtraServiceCheckbox(false)
				handleSelectExtraService()
			}}
			className="ext-footer__btns"
			type="primary"
			key="sleect"
		>
			Chọn
		</Button>,
	]

	// get extra service
	useEffect(() => {
		extraServiceServices
			.getExtraServices()
			.then((response) => {
				if (!response.isError) setExtraServices(response.services)
			})
			.catch(console.log)
	}, [])

	useEffect(() => {
		if (!isActive) {
			setSelectedExtraServices([])
			setDisplaySelectedExtraServices([])
		}

		return () => {
			setSelectedExtraServices([])
			setDisplaySelectedExtraServices([])
		}
	}, [isActive])

	return (
		<div className="banner__tab price__tab">
			{prices && (
				<PriceModal onClose={handleCloseModal} data={prices} visibled={priceModalVisibled} />
			)}
			<Form layout="vertical" onFinish={handleSubmit}>
				<Row gutter={12} wrap>
					<Col md={24} lg={6} sm={24} xs={24}>
						<Form.Item
							label={'Gửi từ'}
							style={{ marginTop: 12 }}
							name="from"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn tỉnh/thành phố',
								},
							]}
						>
							<Select
								placeholder="Tỉnh/Thành Phố"
								suffixIcon={null}
								onChange={handleFromCityChange}
								showSearch
								filterOption={filterOption}
								popupClassName="price__tab-dropdown"
							>
								{cities.map((c) => (
									<Option key={c.provinceID} value={c.provinceID}>
										{c.provinceName}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col md={24} lg={6} sm={24} xs={24} style={{ marginTop: 'auto' }}>
						<Form.Item
							label={''}
							style={{ marginTop: 12 }}
							name="fromProvince"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn quận huyện',
								},
							]}
						>
							<Select
								showSearch
								filterOption={filterOption}
								placeholder="Quận/Huyện"
								suffixIcon={null}
								value={selectedFromDistrict}
								onChange={(value) => setSelectedFromDistrict(value)}
								popupClassName="price__tab-dropdown"
							>
								{fromDistricts?.length &&
									fromDistricts.map((d) => (
										<Option key={d.districtID} value={d.districtID}>
											{d.districtName}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>
					<Col md={24} lg={6} sm={24} xs={24}>
						<Form.Item
							label={'Gửi đến'}
							style={{ marginTop: 12 }}
							name="to"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn tỉnh/thành phố',
								},
							]}
						>
							<Select
								placeholder="Tỉnh/Thành Phố"
								suffixIcon={null}
								onChange={handleToCityChange}
								showSearch
								filterOption={filterOption}
								popupClassName="price__tab-dropdown"
							>
								{cities.map((c) => (
									<Option key={c.provinceID} value={c.provinceID}>
										{c.provinceName}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col md={24} lg={6} sm={24} xs={24} style={{ marginTop: 'auto' }}>
						<Form.Item
							label={''}
							style={{ marginTop: 12 }}
							name="toProvince"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn quận huyện',
								},
							]}
						>
							<Select
								showSearch
								filterOption={filterOption}
								placeholder="Quận/Huyện"
								suffixIcon={null}
								value={selectedToDistrict}
								onChange={(value) => setSelectedToDistrict(value)}
								popupClassName="price__tab-dropdown"
							>
								{toDistricts?.length &&
									toDistricts.map((d) => (
										<Option key={d.districtID} value={d.districtID}>
											{d.districtName}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>
					<Col md={6} lg={6} sm={12} xs={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Vui lòng nhập trọng lượng',
								},
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject('')
										}
										if (!/^[0-9]\d*(\.\d+)?$/.test(value)) {
											return Promise.reject('Sai định dạng trọng lượng')
										}
										if (Number(value) < 0.01) {
											return Promise.reject('Trọng lượng phải lớn hơn 0 Kg')
										}
										return Promise.resolve()
									},
								}),
							]}
							label="Trọng lượng (Kg)"
							style={{ marginTop: 12 }}
							name="weight"
						>
							<Input placeholder="Kg" />
						</Form.Item>
					</Col>
					<Col md={6} lg={6} sm={12} xs={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Vui lòng nhập số lượng',
								},
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject('')
										}
										if (!/^[0-9]\d*(\.\d+)?$/.test(value)) {
											return Promise.reject('Sai định dạng số lượng')
										}
										if (value < 0.01) {
											return Promise.reject('Số lượng phải lớn hơn 0 Kg')
										}
										return Promise.resolve()
									},
								}),
							]}
							label="Số lượng"
							style={{ marginTop: 12 }}
							name="Quantity"
						>
							<Input placeholder="Số lượng" />
						</Form.Item>
					</Col>
					<Col md={4} lg={4} sm={8} xs={8}>
						<Form.Item label="Chiều dài (cm)" style={{ marginTop: 12 }} name="Length">
							<Input placeholder="Cm" />
						</Form.Item>
					</Col>
					<Col md={4} lg={4} sm={8} xs={8}>
						<Form.Item label="Chiều rộng (cm)" style={{ marginTop: 12 }} name="Width">
							<Input placeholder="Cm" />
						</Form.Item>
					</Col>
					<Col md={4} lg={4} sm={8} xs={8}>
						<Form.Item label="Chiều cao (cm)" style={{ marginTop: 12 }} name="Height">
							<Input placeholder="Cm" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<div
							onClick={() => toggleExtraServiceCheckbox(true)}
							className="price__tab-extra__service"
						>
							<label>Dịch vụ gia tăng</label>
							<div className="extra__service-selected">
								{displaySelectedExtraServices.length > 0 ? (
									<>
										{displaySelectedExtraServices.map((e) => (
											<Tag
												key={e}
												closable
												closeIcon={<CloseOutlined />}
												onClose={() => handleRemoveExtraService(e)}
											>
												{extraServices.find((s) => s.serviceID == e)?.serviceName}
											</Tag>
										))}
									</>
								) : (
									<div className="no__service">
										<span className="text">Chọn dịch vụ gia tăng</span>
										<span className="icon">
											<RightOutlined />
										</span>
									</div>
								)}
							</div>
						</div>
						{extraServices?.length ? (
							<Modal
								footer={ExtraServiceFooter}
								onCancel={handleCancelExtraService}
								onOk={() => toggleExtraServiceCheckbox(false)}
								open={extraServiceCheckbox}
								getContainer=".price__tab-extra__service"
								width={784}
								title="Dịch vụ gia tăng"
								destroyOnClose
							>
								<ExtraServiceCheckbox
									onChange={handleExtraServiceChange}
									defaultValue={selectedExtraServices}
									data={extraServices}
								/>
							</Modal>
						) : null}
					</Col>
					<Col span={24} style={{ fontStyle: 'italic', fontWeight: 600 }}>
						Ghi chú: Đối với hàng hoá có kích thước lớn, vui lòng nhập kích thước dài rộng cao để
						tính giá được chính xác hơn
					</Col>

					<Col span={24}>
						<div className="price__tab-btn__wrapper">
							<Button loading={loading} htmlType="submit" type="primary">
								Tính giá
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

export default React.memo(PriceTab)
