import { LoadingOutlined } from '@ant-design/icons'

const Loading = () => {
	return (
		<div
			style={{ display: 'flex', justifyContent: 'center', minHeight: 700 }}
			className="c-loading"
		>
			<div className="loading-container">
				<LoadingOutlined style={{ fontSize: 32, color: 'var(--my-red)', margin: '32px 0px' }} />
			</div>
		</div>
	)
}

export default Loading
