import { CloseOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, notification, Row, Select, Tag } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import useDistricts from 'src/hooks/useDistricts'
import useWards from 'src/hooks/useWards'
import addressService from 'src/services/address.service'
import commonService from 'src/services/common.service'
import extraServiceServices from 'src/services/extraService.service'
import { rmVieTones } from 'src/utils/validation'
import ExtraServiceCheckbox from './ExtraServiceCheckbox'
import PriceModal from './PriceModal'
import { toCapitalCase } from 'src/utils/common'

const { Option } = Select

const PriceInternationalTab = ({ isActive }) => {
	const { cities } = addressService.usePMSCities()
	const { countries } = addressService.usePMSCountry()

	const [loading, setLoading] = useState(false)

	const [prices, setPrices] = useState(null)
	const [priceModalVisibled, setPriceModalVisibled] = useState(false)

	const handleCloseModal = () => {
		setPriceModalVisibled(false)
		setPrices(null)
	}

	const handleSubmit = (values) => {
		setLoading(true)

		const payload = {
			FromCountryID: values.FromCountryID,
			ToCountryID: values.ToCountryID,
			Weight: values.Weight,
			IsReturn: false,
			Length: Number(values.Length),
			Width: Number(values.Width),
			Height: Number(values.Height),
			Quantity: Number(values.Quantity),
			AcceptTime: new Date().toISOString(),
		}

		commonService
			.calcPriceInternational(payload)
			.then((response) => {
				if (!response.isError) {
					setPrices(response.prices)
					setPriceModalVisibled(true)
				} else {
					throw new Error(response.errorMessage)
				}
			})
			.catch((e) => {
				notification.error({
					message: 'Có lỗi xảy ra!',
					description: e.message,
				})
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const filterOption = useCallback((input, option) => {
		return (
			rmVieTones(option.children).toLowerCase().includes(rmVieTones(input).toLowerCase()) ||
			option.key.toLowerCase().includes(input.toLowerCase())
		)
	}, [])

	return (
		<div className="banner__tab price__tab">
			{prices && (
				<PriceModal onClose={handleCloseModal} data={prices} visibled={priceModalVisibled} />
			)}
			<Form layout="vertical" onFinish={handleSubmit}>
				<Row gutter={12} wrap>
					<Col md={24} lg={12} sm={24} xs={24}>
						<Form.Item
							label={'Gửi từ'}
							initialValue={'VN'}
							style={{ marginTop: 12 }}
							name="FromCountryID"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn quốc gia gửi',
								},
							]}
						>
							<Select
								placeholder="Quốc gia"
								suffixIcon={null}
								showSearch
								filterOption={filterOption}
								popupClassName="price__tab-dropdown"
								defaultValue={'VN'}
							>
								{countries?.map((c) => (
									<Option key={c.countryID} value={c.countryID}>
										{toCapitalCase(c.countryName)}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col md={24} lg={12} sm={24} xs={24}>
						<Form.Item
							label={'Gửi đến'}
							style={{ marginTop: 12 }}
							name="ToCountryID"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn quốc gia',
								},
							]}
						>
							<Select
								placeholder="Quốc gia"
								suffixIcon={null}
								showSearch
								filterOption={filterOption}
								popupClassName="price__tab-dropdown"
							>
								{countries?.map((c) => (
									<Option key={c.countryID} value={c.countryID}>
										{toCapitalCase(c.countryName)}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Vui lòng nhập trọng lượng',
								},
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject('')
										}
										if (!/^[0-9]\d*(\.\d+)?$/.test(value)) {
											return Promise.reject('Sai định dạng trọng lượng')
										}
										if (value < 0.01) {
											return Promise.reject('Trọng lượng phải lớn hơn 0 Kg')
										}
										return Promise.resolve()
									},
								}),
							]}
							label="Trọng lượng (Kg)"
							style={{ marginTop: 12 }}
							name="Weight"
						>
							<Input placeholder="Kg" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							rules={[
								{
									required: true,
									message: 'Vui lòng nhập số lượng',
								},
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.reject('')
										}
										if (!/^[0-9]\d*(\.\d+)?$/.test(value)) {
											return Promise.reject('Sai định dạng số lượng')
										}
										if (value < 0.01) {
											return Promise.reject('Số lượng phải lớn hơn 0 Kg')
										}
										return Promise.resolve()
									},
								}),
							]}
							label="Số lượng"
							style={{ marginTop: 12 }}
							name="Quantity"
						>
							<Input placeholder="Số lượng" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Chiều dài (cm)" style={{ marginTop: 8 }} name="Length">
							<Input placeholder="Cm" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Chiều rộng (cm)" style={{ marginTop: 12 }} name="Width">
							<Input placeholder="Cm" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Chiều cao (cm)" style={{ marginTop: 12 }} name="Height">
							<Input placeholder="Cm" />
						</Form.Item>
					</Col>
					<Col span={24} style={{ fontStyle: 'italic', fontWeight: 600 }}>
						Ghi chú: Giá bao gồm phụ phí nhiên liệu, nhưng không bao gồm các loại thuế, thuế hải
						quan và các lệ phí khác có thể áp dụng cho lô hàng và có thể thay đổi sau khi 247Express
						tiếp nhận và kiểm tra hàng hoá.
					</Col>
					<Col span={24}>
						<div className="price__tab-btn__wrapper">
							<Button loading={loading} htmlType="submit" type="primary">
								Tính giá
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

export default React.memo(PriceInternationalTab)
