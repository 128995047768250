import { Breadcrumb } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { DotIcon } from 'src/components/CustomIcon'
import MENU_CONFIGS from 'src/configs/menu'
import { GlobalContext } from 'src/pages/_app'
import { stripQueryStringAndHashFromPath } from 'src/utils/common'

// recursive function to flat the menu data to an array
// [{ url: 'tin-tuc', children: ['tin-hot']}] => ['tin-tuc', 'tin-hot', ...]
const mineRoutePath = (path, level, array) => {
	if (MENU_CONFIGS.DISABLED_BREADSCRUMB.findIndex((o) => o.Url == path) < 0) {
		if (level >= 0) {
			level--
			let newArr = [...array]
			array.forEach((e) => {
				newArr = newArr.concat(e.Children || [])
			})

			return mineRoutePath(path, level, newArr)
		}

		if (array?.length) return array.find((e) => e.Url === path)

		return null
	}
	return null
}

const NextBreadcrumb = ({
	rootLabel = 'Trang chủ',
	children,
	isPrivateAction,
	extraPartial,
	isNegative,
	...optionProps
}) => {
	let router = useRouter()
	const { menuItems, user } = useContext(GlobalContext)
	const [breadcrumbs, setBreadcrumbs] = useState([])
	const [title, setTitle] = useState('')

	useEffect(() => {
		const { asPath } = router
		const url = stripQueryStringAndHashFromPath(asPath)
		const routeLevels = url.split('/').slice(1)
		const breadcrumbData = routeLevels.map((item, index) => {
			let currentPath =
				index > 0 ? '/' + routeLevels.slice(0, index).join('/') + `/${item}` : `/${item}`
			return mineRoutePath(currentPath, index, menuItems)
		})

		setBreadcrumbs(
			breadcrumbData
				.filter((e) => !!e)
				.map((item) => ({
					breadcrumbName: item.SubName || item.Name,
					path: item.Url,
				}))
		)
	}, [router, menuItems])

	useEffect(() => {
		if (breadcrumbs[0]) setTitle(breadcrumbs[0].breadcrumbName)
	}, [breadcrumbs])

	if (!breadcrumbs.length) {
		return null
	}

	if (breadcrumbs[0]?.path === '/') {
		return null
	}

	return (
		<div className="breadcrumb-container">
			<div className="breadcrumb-path">
				<div className="headerbreadcrumb-title" style={{ textTransform: 'uppercase' }}>
					<h1>{title}</h1>
				</div>
				<Breadcrumb
					{...optionProps}
					separator={<DotIcon style={{ transform: 'translateY(-3px)', color: '#5E6278' }} />}
				>
					<Breadcrumb.Item key={rootLabel}>
						<Link href="/">
							{rootLabel}
						</Link>
					</Breadcrumb.Item>

					{breadcrumbs.length >= 1 &&
						breadcrumbs.map((breadcrumb, index) => (
							<Breadcrumb.Item key={breadcrumb.path}>
								<Link href={breadcrumb.path}>
									<a aria-label={"247Express-Nhanh hơn bạn nghĩ"}
										style={
											index == breadcrumbs.length - 1 && !extraPartial
												? {
														fontWeight: 600,
														fontFamily: 'SF Pro Display',
														color: isNegative ? 'white' : '#5e6278',
												  }
												: {}
										}
									>
										{breadcrumb.breadcrumbName}
									</a>
								</Link>
							</Breadcrumb.Item>
						))}
					{extraPartial && (
						<Breadcrumb.Item key={extraPartial}>
							<Link href={'#'}>
								<a
									style={{
										fontWeight: 600,
										fontFamily: 'SF Pro Display',
										color: isNegative ? 'white' : '#5e6278',
									}}
								>
									{extraPartial}
								</a>
							</Link>
						</Breadcrumb.Item>
					)}
				</Breadcrumb>
			</div>
			{isPrivateAction ? user && children : children}
		</div>
	)
}

const withActionBtn = (Btn) => {
	return function BreadCrumbWithBtn({
		rootLabel = 'Trang chủ',
		isPrivateAction = true,
		...others
	}) {
		return (
			<NextBreadcrumb rootLabel={rootLabel} isPrivateAction={isPrivateAction}>
				{Btn}
			</NextBreadcrumb>
		)
	}
}

NextBreadcrumb.withActionBtn = withActionBtn
export default NextBreadcrumb
