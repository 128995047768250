const { NOTIF_APIS, PMS_APIs } = require('src/configs/API')
const { default: request } = require('src/utils/request')

const subcribeNotification = (userId) => {
	return request.notifApi({
		url: NOTIF_APIS.SUBCRIBE_NOTIF,
		method: 'POST',
		data: {
			UserID: userId,
			DeviceID: 'web',
			AppID: 0,
		},
	})
}

const getNotifications = ({
	username,
	fromDate,
	toDate,
	textSearch,
	orderType,
	statusId,
	pageIndex = 0,
	pageSize,
}) => {
	// return request.pmsApi({
	// 	url: PMS_APIs.API_GET_NOTIFICATIONS,
	// 	method: 'POST',
	// 	data: {
	// 		UserName: username,
	// 		// FromDate: fromDate,
	// 		FromDate: '2022-09-01',
	// 		// ToDate: toDate,
	// 		ToDate: '2022-09-30',
	// 		TextSearch: textSearch,
	// 		OrderType: orderType,
	// 		StatusID: statusId,
	// 		PageIndex: pageIndex,
	// 		PageSize: pageSize,
	// 	},
	// })
	return {
		isError: false
	};
}

const notificationService = {
	subcribeNotification,
	getNotifications,
}

export default notificationService
