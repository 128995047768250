import { Col, Row } from 'antd'
import moment from 'moment'
import Link from 'next/link'
import { NotifType1Icon } from '../CustomIcon'
import Loading from '../Loading'

const NotificationsList = ({ data, loading }) => {
	return (
		<div className="notification-list">
			<div className="notification-list__container">
				<div className="notification-list__title">
					<span>Thông báo mới nhất</span>
				</div>
				{loading ? (
					<Loading />
				) : data.length > 0 ? (
					<>
						<div className="notification-list__list">
							{data.map((e) => (
								<div key={e.Title} className="notification-list__item">
									<div className="item__header">
										<Row gutter={12} align="middle">
											<Col>
												<div className="item__icon">
													<NotifType1Icon />
												</div>
											</Col>
											<Col>
												<span className="item__title">{e.Title}</span>
											</Col>
										</Row>
									</div>
									<div className="item__content">
										<p>{e.Content}</p>
									</div>
									<div className="item__info">
										<Row justify="space-between" align="center">
											<Col>
												<span className="item__timestamp">
													{moment(e.CreatedDate).format('hh:mm DD/MM/YYYY')}
												</span>
											</Col>
											<Col>
												<span className="item__status">{e.StatusName}</span>
											</Col>
										</Row>
									</div>
								</div>
							))}
						</div>
						<div className="notification-list__see-more">
							<Link href="/khach-hang/ho-so-ca-nhan?tab=notif">Xem tất cả</Link>
						</div>
					</>
				) : (
					<p style={{ textAlign: 'center', fontStyle: 'italic' }}>Không có thông báo!</p>
				)}
			</div>
		</div>
	)
}

export default NotificationsList
