import { SIGN_IN_REDIRECT, SIGN_UP_REDIRECT } from './API'
const MENU_CONFIGS = {
	SIGN_IN_REDIRECT: SIGN_IN_REDIRECT, // '/dang-nhap', // đường dẫn đăng nhập tới web v3 hoặc v4
	//SIGN_IN_REDIRECT: 'https://khachhang.247express.vn/login',
	SIGN_UP_REDIRECT: SIGN_UP_REDIRECT, //'/dang-ky', // đường dẫn đăng ký tới web v3 hoặc v4
	//SIGN_UP_REDIRECT: 'https://khachhang.247express.vn/dang-ky-tai-khoan',
	DISABLED_ITEMS_WHITELIST: ['/dich-vu'], // Danh sách các URL của menu không cho phép click redirect
	ABOUT_US_SLUG: '/ve-chung-toi',
	DISABLED_BREADSCRUMB: [
		{
			Key: 'tuyen_dung',
			Name: 'Tuyển dụng',
			Url: '/tuyen-dung',
			SubName: 'Tuyển dụng',
		},
		{
			Key: 'shipment_menu',
			Name: 'Đơn hàng',
			Url: '/theo-doi-don-hang',
			SubName: '',
		},
		{
			Key: 'dinh_vi_buu_pham',
			Name: 'Định vị bưu phẩm',
			Url: '/tra-cuu-van-don',
			SubName: 'Tra cứu vận đơn',
		},
		{
			Key: 'buu_cuc',
			Name: 'Bưu cục',
			Url: '/tim-buu-cuc',
			SubName: 'Tìm bưu cục',
		},
		{
			Key: 'don_hang',
			Name: 'Đơn hàng',
			Url: '/khach-hang/don-hang',
			SubName: 'Đơn hàng',
		},
		{
			Key: 'ho_tro',
			Name: 'Hỗ trợ',
			Url: '/ho-tro',
			SubName: 'Hỗ trợ',
		},
		// {
		// 	Key: 'khieu_nai',
		// 	Name: 'Khiếu nại',
		// 	Url: '/ho-tro/khieu-nai',
		// 	SubName: 'Khiếu nại',
		// },
		{
			Key: 'tao_don_hang',
			Name: 'Đặt đơn hàng',
			Url: '/khach-hang/tao-don-hang',
			SubName: 'Đặt đơn hàng',
		},
		{
			Key: 'tao_don_hang',
			Name: 'Đặt đơn hàng',
			Url: '/khach-hang/import-don-hang/them-hang-loat',
			SubName: 'Đặt đơn hàng',
		},
		{
			Key: 'tao_don_hang',
			Name: 'Đặt đơn hàng',
			Url: '/khach-hang/anh-xac-nhan-phat',
			SubName: 'Đặt đơn hàng',
		},
		{
			Key: 'dashboard',
			Name: 'dashboard',
			Url: '/khach-hang/dashboard',
			SubName: 'dashboard',
		},
		{
			Key: 'import-don-hang',
			Name: 'import-don-hang',
			Url: '/khach-hang/import-don-hang',
			SubName: 'import-don-hang',
		},
		{
			Key: 'quan-ly-yeu-cau-dich-vu',
			Name: 'quan-ly-yeu-cau-dich-vu',
			Url: '/khach-hang/quan-ly-yeu-cau-dich-vu',
			SubName: 'quan-ly-yeu-cau-dich-vu',
		},
	],
}

export default MENU_CONFIGS
