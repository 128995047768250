require('src/styles/antd.less')
require('src/styles/reset.less')
require('src/styles/root.less')
require('src/styles/components.less')
require('src/styles/pages.less')
require('src/styles/index.less')
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { ArrowUpOutlined, PhoneOutlined } from '@ant-design/icons'

import { BackTop, ConfigProvider } from 'antd'
import vi from 'antd/lib/locale/vi_VN'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/vi'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import { MainLayout } from 'src/components/Layout/MainLayout'
import NextBreadcrumb from 'src/components/NextBreadcrumb'
import APIConstants, { PMS_APIs, PMS_ENDPOINT, V4_ENDPOINT } from 'src/configs/API'
import BASE_CONFIG from 'src/configs/base'
import menuServices from 'src/services/menu.service'
import notificationService from 'src/services/notification.service'
import request from 'src/utils/request'
import { SWRConfig } from 'swr'
import * as Sentry from '@sentry/nextjs'

moment.locale('vi')

export const GlobalContext = React.createContext()

function MyApp({ Component, pageProps }) {
	const router = useRouter()
	axios.defaults.baseURL = V4_ENDPOINT
	axios.defaults.headers.common = {
		'content-type': 'application/json',
		'Cache-Control': 's-maxage=86400',
	}
	axios.interceptors.request.use(
		function (config) {
			return config
		},
		function (error) {
			Sentry.captureException(error)
			return Promise.reject(error)
		}
	)
	axios.interceptors.response.use(
		function (response) {
			//Unauthorized
			if (response.status === 401) {
				localStorage.clear();
				router.push(window.location.origin+'/dang-nhap')
				return
			}
			return response
		},
		function (error) {
			Sentry.captureException(error)
			return Promise.reject(error)
		}
	)

	const Layout = Component.Layout ?? MainLayout
	const Breadcrumb = Component.Breadcrumb || NextBreadcrumb
	const [wards, setWards] = useState([])
	const [districts, setDistricts] = useState([])
	const [extraServices, setExtraServices] = useState([])
	const [serviceTypes, setServiceTypes] = useState([])
	const [cities, setCities] = useState([])
	const [user, setUser] = useState(null)
	const [menuItems, setMenuItems] = useState([])
	const [systemCities, setSystemCities] = useState([])
	const getExtraServiceClient = () => {
		request
			.pmsApi({
				url: PMS_APIs.API_GET_ALL_SERVICE_FOR_CLIENT,
				// data: {},
				method: 'GET',
			})
			.then((response) => {
				if (!response.isError) setExtraServices(response.services)
			})
			.catch(console.log)
	}

	const getServiceTypes = () => {
		request
			.pmsApi({
				url: PMS_APIs.API_GET_ALL_PRIVATE_EXTRA_SERVICE,
				data: {},
				method: 'POST',
			})
			.then((response) => {
				if (!response.isError) setServiceTypes(response.serviceTypes)
			})
			.catch(console.log)
	}

	const getCities = () => {
		request
			.pmsApi({
				url: PMS_APIs.API_GET_PROVINCES,
				data: {
					ID: 'VN',
				},
				method: 'POST',
			})
			.then((response) => {
				if (!response.isError) setCities(response.provinces.filter((e) => e.isActive))
			})
			.catch(console.log)
	}

	const getSystemCities = () => {
		request
			.v4Api({
				url: APIConstants.API_GET_SYSTEM_PROVINCE,
			})
			.then((response) => {
				setSystemCities(response)
			})
			.catch(console.log)
	}

	useEffect(() => {
		if (document.location.href.indexOf('he-thong-bang-gia-1') > -1) {
			document.location.href = '/'
		}
		//setIsPrintingUrl(window.location.href.indexOf('in-don-hang')>-1);
		getCities()
		getSystemCities()

		const userData = localStorage.getItem('user')
		if (userData && userData != 'null') {
			setUser(JSON.parse(userData))
			getServiceTypes()
			getExtraServiceClient()
		}
	}, [])

	useEffect(() => {
		const getMenuItems = async () => {
			try {
				const response = await menuServices.getHeaderMenuItems()
				if (response.Children) {
					let items = response.Children.slice(1)
					setMenuItems(items)
				}
			} catch (error) {
				console.log(error)
			}
		}
		getMenuItems()

		if (user?.customerID) {
			// notificationService
			// 	.subcribeNotification(user.customerID)
			// 	.then((response) => {
			// 		console.log(response)
			// 	})
			// 	.catch(console.log)
		}
	}, [user])

	useEffect(() => {
		const handler = (ev) => {
			let userID =
				typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('user'))?.userID : ''
			// console.log('mess', ev)

			// if (document.getElementsByClassName('iframe-box-chat-bizfly')) {
			// 	console.log(document.getElementsByClassName('iframe-box-chat-bizfly')[0])
			// 	document.getElementsByClassName('cwbb__chat-input-text_textarea')[0].value = userID
			// }
		}
		
		window.addEventListener('message', handler)
		return () => window.removeEventListener('message', handler)
	}, [])

	return (
		<SWRConfig
			value={{
				fetcher: (url) => axios.get(url).then((res) => res.data),
				dedupingInterval: 50000,
			}}
		>
			<ConfigProvider locale={vi}>
				<GlobalContext.Provider
					value={{
						districts,
						serviceTypes,
						extraServices,
						cities,
						menuItems,
						systemCities,
						user,
						wards,
						districts,
						getUser: setUser,
					}}
				>
					{/* Google anal */}
					{/* <Script strategy="lazyOnload" src="/script/service-worker.js" /> */}
					<Script strategy="lazyOnload" src="/script/service-worker.js" />
					<Script strategy="lazyOnload" src="/script/script.js" />
					<Script
						id="google-tag-manager"
						strategy="afterInteractive"
						src="https://www.googletagmanager.com/gtag/js?id=UA-7314325-37"
					/>
					<Script
						id="google-tag-manager"
						strategy="afterInteractive"
						src="https://www.googletagmanager.com/gtag/js?id=G-PWYR2RLJE7"
					/>
					<noscript />
					{/* <Script
						crossOrigin="use-credentials"
						id="chat-bizfly"
						strategy="lazyOnload"
						onLoad={() => {
							console.log('Script has loaded')
						}}
						src="https://chat.bizfly.vn/script/dip-v2/fefd6623-8ad5-4f34-84bf-882fdddcbc24?ref=webEmbed_e3455d21e9339ad371391f5ace10f184"
					/> */}
					
					
					<Layout breadcrumb={<Breadcrumb />}>
						<Component {...pageProps} />
						{/* <div style={{ zIndex: 1 }}>
							<MessengerCustomerChat pageId="1848208872133175" language="vi_VN" />
						</div> */}
						<div className="telephone--mobile">
							<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} href={`tel:${BASE_CONFIG.HOTLINE}`}>
								<PhoneOutlined style={{ fontSize: 20, color: 'white' }} />
							</a>
						</div>
						<BackTop style={{ bottom: 148, right: 34 }}>
							<div
								style={{
									height: 50,
									width: 50,
									lineHeight: '40px',
									borderRadius: '50%',
									backgroundColor: '#CC0000',
									color: '#fff',
									textAlign: 'center',
									fontSize: 14,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<ArrowUpOutlined style={{ fontSize: 18 }} />
							</div>
						</BackTop>
					</Layout>
					{/* <div className="container web-social">
						<div className="social-item">
							<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} target={'_blank'} rel="noreferrer" href={BASE_CONFIG.FACEBOOK}>
								<HomeFbIcon />
							</a>
						</div>
						<div className="social-item">
							<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} target={'_blank'}>
								<HomeZaloIcon />
							</a>
						</div>
						<div className="social-item" rel="noreferrer" href={BASE_CONFIG.YOUTUBE}>
							<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} target={'_blank'}>
								<HomeYoutubeIcon />
							</a>
						</div>
					</div> */}
				</GlobalContext.Provider>
			</ConfigProvider>
		</SWRConfig>
	)
}

export default MyApp
