import Image from 'next/image'

const CardView = ({ imgUrl, title, content, rtl, redTexts = 2, highlightColor = '#9F3123', link }) => {
	const titleTexts = title?.split(' ') || []
	const redText = (titleTexts.length > redTexts && titleTexts.slice(0, redTexts).join(' ')) || title
	const blackText = redText && titleTexts.slice(redTexts).join(' ')

	return (
		<div className={'card__view ' + ((rtl && 'rtl') || '')}>
			<div className="card__view-container">
				<div className="card__view-image">
					{/* eslint-disable-next-line @next/next/no-img-element */}
					<Image src={imgUrl} alt={title} layout="fill" />
				</div>
				<div className="card__view-body">
					<div className="body-container">
						<a href={link}>
						<span className={'body-title ' + ((rtl && 'rtl') || '')}>
							{redText && <span style={{ color: highlightColor }}>{redText}</span>}
							{blackText && <span style={{ color: 'var(--my-text-color)' }}> {blackText}</span>}
						</span>
						</a>
						<div className="body-content" style={{ whiteSpace: 'pre-line' }}>
							{content}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CardView
