import { Tabs } from 'antd'
import { useState } from 'react'
import PriceTab from './PriceTab'
import TrackingTab from './TrackingTab'
import PriceInternationalTab from './PriceInternationalTab'

const { TabPane } = Tabs

const TrackingForm = () => {
	const [selectedKey, setSelectedKey] = useState('tracking')
	return (
		<div className={'tracking__form ' + selectedKey}>
			<Tabs defaultActiveKey={selectedKey} onChange={(key) => setSelectedKey(key)}>
				<TabPane tab="Theo dõi" key="tracking">
					<TrackingTab />
				</TabPane>
				<TabPane tab="Tính cước nội địa" key="price">
					<PriceTab isActive={selectedKey == 'price'} />
				</TabPane>
				<TabPane tab="Tính cước quốc tế" key="global-price">
					<PriceInternationalTab isActive={selectedKey == 'global-price'} />
				</TabPane>
			</Tabs>
		</div>
	)
}

export default TrackingForm
