import APIConstants from 'src/configs/API'
import request from 'src/utils/request'

const getHeaderMenuItems = async () => {
	return request.v4Api({
		url: `${APIConstants.API_GET_MENU_ITEMS}/5ca08bcb77fa787129844f18`,
	})
}

const getFooterHelpMenuItems = async () => {
	return request.v4Api({
		url: `${APIConstants.API_GET_MENU_ITEMS}/5ca08bcb77fa787129844f19`,
	})
}

const getFooterInfoMenuItems = async () => {
	return request.v4Api({
		url: `${APIConstants.API_GET_MENU_ITEMS}/5ca08bcb77fa787129844f1a`,
	})
}

const getFooterGeneralMenuItems = async () => {
	return request.v4Api({
		url: `${APIConstants.API_GET_MENU_ITEMS}/630c34d0f2530000ea003ce2`,
	})
}

const menuServices = {
	getHeaderMenuItems,
	getFooterHelpMenuItems,
	getFooterInfoMenuItems,
	getFooterGeneralMenuItems,
}

export default menuServices
