import { ClockCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import Link from 'next/link'
import BASE_CONFIG from 'src/configs/base'
import { convertImagePath } from 'src/utils/common'
import Image from 'next/image'

const News = ({ data }) => {
	return (
		<div className="c-news">
			<div className="news-container">
				<div className="news-thumbnail">
					<Link
						href={`/tin-tuc/${data.CategorySlug}/${data.SEO.Slug || BASE_CONFIG.DEFAULT_SEO_SLUG}/${
							data.Id
						}`}
					>
							<Image
								src={convertImagePath(data.Thumb)}
								alt={data.Name?? "247Express-Nhanh hơn bạn nghĩ"}
								layout="fill"
								objectFit="cover"
							/>
					</Link>
				</div>
				<div className="news-info">
					<span className="info-label">
						Tin mới{' '}
						<span className="info-timestamp-mb">
							<ClockCircleOutlined style={{ marginRight: 2 }} />
							{moment(data.CreatedDate).format('DD/MM/YYYY')}
						</span>
					</span>
					<Link
						href={`/tin-tuc/${data.CategorySlug}/${data.SEO.Slug || BASE_CONFIG.DEFAULT_SEO_SLUG}/${
							data.Id
						}`}
					>
						
							<h2 className="info-title">{data.Name}</h2>
						
					</Link>
					<p className="info-description">{data.Summary}</p>
					<span className="info-timestamp">
						<ClockCircleOutlined style={{ marginRight: 12, transform: 'translateY(-4px)' }} />
						{moment(data.CreatedDate).format('DD/MM/YYYY')}
					</span>
				</div>
			</div>
		</div>
	)
}

export default News
