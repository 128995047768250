import { Button, Form, Input, notification } from 'antd'
import { useContext, useState } from 'react'
import { GlobalContext } from 'src/pages/_app'
import accountService from 'src/services/account.service'

const PasswordForm = () => {
	const [newPw, setNewPw] = useState('')
	const { user } = useContext(GlobalContext)
	const [loading, setLoading] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)

	const handleSubmit = (values) => {
		setLoading(true)
		accountService
			.changePassword({
				// username: user.userID,
				...values,
			})
			.then((response) => {
				if (response.isError)
					notification.error({
						message: response.errorMessage,
					})
				else {
					setIsSuccess(true)
					localStorage.removeItem('user')
					window.location.href = '/dang-nhap'
				}
			})
			.catch(console.log)
			.finally(() => {
				setLoading(false)
			})
	}

	const Success = (
		<div className="user__option-success">
			{/* eslint-disable-next-line @next/next/no-img-element */}
			<img src="/images/contact_success.png" alt="Thay đổi mật khẩu thành công" />
			<h4>Thay đổi mật khẩu thành công!</h4>
		</div>
	)

	if (isSuccess) return Success

	return (
		<div className="user__options-detail">
			<div className="password__form">
				<Form layout="vertical" onFinish={handleSubmit}>
					<Form.Item
						rules={[
							{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại' },
							// {
							// 	min: 6,
							// 	message: 'Mật khẩu phải từ 6 kí tự',
							// },
						]}
						label="Mật khẩu hiện tại"
						name="oldPassword"
					>
						<Input.Password />
					</Form.Item>
					<Form.Item
						rules={[
							{
								required: true,
								message: 'Mật khẩu không được để trống',
							},
							{
								pattern: /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
								message:
									'Mật khẩu tối thiểu có 8 ký tự số và chữ, trong đó có ít nhất một ký tự chữ viết hoa, một chữ số.',
							},
						]}
						label="Mật khẩu mới"
						name="NewPassword"
						style={{ marginTop: 24 }}
					>
						<Input.Password onChange={(e) => setNewPw(e.target.value)} />
					</Form.Item>
					<Form.Item
						rules={[
							{ required: true, message: 'Vui lòng nhập xác nhận mật khẩu' },
							// {
							// 	min: 6,
							// 	message: 'Mật khẩu phải từ 6 kí tự',
							// },
							() => ({
								validator(_, value) {
									if (value && value !== newPw) {
										return Promise.reject(new Error('Mật khẩu không khớp!'))
									}
									return Promise.resolve()
								},
							}),
						]}
						label="Xác nhận mật khẩu mới"
						name="ConfirmPassword"
						style={{ marginTop: 24 }}
					>
						<Input.Password />
					</Form.Item>
					<Form.Item style={{ marginTop: 24 }}>
						<div className="form-submit">
							<Button
								type="primary"
								htmlType="submit"
								style={{
									height: 44,
									fontWeight: 500,
									fontSize: 16,
									padding: '0px 32px',
								}}
								block
								loading={loading}
							>
								Xác nhận
							</Button>
						</div>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
}

export default PasswordForm
