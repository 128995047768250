import { IMAGE_HOST } from 'src/configs/API'

export const delay = async (s) => {
	return new Promise((resolve) => {
		setTimeout(resolve, s)
	})
}

export const chooseFile = (handleFile) => {
	var input = document.createElement('input')
	input.multiple = true
	input.type = 'file'
	input.hidden = true
	input.accept = 'image/png, image/jpeg, image/gif'

	document.body.appendChild(input)
	input.addEventListener('change', (e) => {
		var fileList = e?.target.files
		handleFile(Array.from(fileList))
	})

	input.click()
}

//development only
export const convertImagePath = (p) => {
	// return p.replace('https://admin-v3-dev.247post.vn/files', 'https://admin.247express.vn/files/')
	return p
}

//development only
export const genImageUrl = (p) => {
	return IMAGE_HOST + p
}

// convert number unit to characters
// 1.000.000 => 1M
// 100.000 => 100K
export const convertNumberToShortText = (number, fixed) => {
	if (number < 1e3) return number
	if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(fixed) + 'K'
	if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(fixed) + 'M'
	if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(fixed) + 'B'
	if (number >= 1e12) return +(number / 1e12).toFixed(fixed) + 'T'
}

export const formatNumberWithCommas = (number, fixed) => {
	if (number !== 'null' && number !== 'undefined') {
		number = Number(number).toFixed(fixed)
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	} else {
		return ''
	}
}

export const formatMoney = (number) => {
	return number?.toLocaleString('it-IT', { style: 'currency', currency: 'VND' }) || 0
}

export const formatMoneyWithoutCurrency = (number) => {
	return number?.toLocaleString('it-IT') || 0
}

export const formatWeight = (number) => {
	return number?.toLocaleString('vi-VN', { style: 'decimal' }) || 0
}

export function stripQueryStringAndHashFromPath(url) {
	return url.split('?')[0].split('#')[0]
}

// chuẩn hóa lại Url của menu từ API để phù hợp vs FE
export function preparePath(p) {
	// FE tự thêm folder page '/khach-hang',
	return p.replace('/khach-hang', '')
}

// convert a string to capital case
// HA NOI => Ha Noi
export function toCapitalCase(str) {
	return str
		.toLowerCase()
		.split(' ')
		.map((p) => {
			if (!p[0]) return p
			return p[0].toUpperCase() + p.slice(1)
		})
		.join(' ')
}

// Remove all falsy properties from object
// { name: "", age: 0, addr: "Ha Noi", isMale: false, job: undefined,...} => { addr: "Ha Noi"}
export function removeNilKeys(object) {
	const newObj = { ...object }
	Object.keys(newObj).forEach((key) => {
		if (!newObj[key]) {
			delete newObj[key]
		}
	})

	return newObj
}

export function toLowerCaseNonAccentVietnamese(str) {
	str = str.toLowerCase()
	str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
	str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
	str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
	str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
	str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
	str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
	str = str.replace(/đ/g, 'd')
	str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
	str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
	return str
}

export const handleURI = (param) => {
	return param
		.trim()
		.replace(/ /g, '')
		.split(',')
		.map((item) => encodeURIComponent(item.trim()))
		.toString()
}
