import * as api from 'src/configs/API'
const { default: request } = require('src/utils/request')

const changePassword = async (payload) => {
	const { oldPassword, NewPassword, ConfirmPassword } = payload
	return request.pmsApi({
		method: 'POST',
		url: api.PMS_APIs.API_CLIENT_CHANGE_PASSWORD,
		data: {
			OldPassword: oldPassword,
			NewPassword: NewPassword,
			ConfirmPassword: ConfirmPassword,
		},
	})
}

const accountService = {
	changePassword,
}

export default accountService
