import moment from 'moment'
import Head from 'next/head'
import BASE_CONFIG from 'src/configs/base'

// Dùng trong các trang customer, yêu cầu SEO

export function SEO({ data }) {
	const {
		title = '247Express',
		description = 'Nhanh hơn bạn nghĩ',
		url = BASE_CONFIG.PAGE_URL,
		thumbnailUrl = 'https://247express.vn/images/logo.png',
		keywords = '',
		newsKeywords = '',
		publishDate = moment().format('YYYY-MM-DD[T]HH:mm:ss'),
		redirect = ''
	} = data

	return (
		<Head>
			<title>{title || `${BASE_CONFIG.SLOGAN}`}</title>
			{/* <!-- META FOR PAGE --> */}
			<meta name="keywords" content={keywords} />
			<meta name="description" content={description} />
			<meta name="news_keywords" content={newsKeywords} />
			<meta charSet="utf-8" />
			<meta
				content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=1"
				name="viewport"
			/>
			<meta httpEquiv="X-UA-Compatible" content="IE=100" />
			<meta property="fb:app_id" content="629785241653186" />
			<meta name="apple-mobile-web-app-capable" content="yes" />
			<meta name="apple-mobile-web-app-title" content={BASE_CONFIG.PAGE_SITE} />
			<meta name="tt_article_id" content="1000000" />
			<meta name="tt_category_id" content="1000000" />
			<meta name="tt_site_id" content="1000000" />
			<meta name="tt_site_id_new" content="1000000" />
			<meta name="tt_list_folder" content="1000000" />
			<meta name="tt_page_type" content="site" />
			<meta name="tt_page_type_new" content="1" />

			<meta name="google-site-verification" content="5wTQcUXrHDtcX90g1hnCUqLLLh1xxdVujdSg-ysqsDI" />
			<meta name="facebook-domain-verification" content="ped9rc25utbc8qhvedjn91c32bk97h" />

			{/* <!-- META FOR FACEBOOK --> */}

			<meta property="og:site_name" content={BASE_CONFIG.PAGE_SITE} />
			<meta property="og:rich_attachment" content="true" />
			<meta property="og:type" content="website" />
			<meta property="og:url" itemProp="url" content={url} />
			<meta property="og:image" itemProp="thumbnailUrl" content={thumbnailUrl} />
			<meta property="og:image:width" content="800" />
			<meta property="og:image:height" content="354" />
			<meta content={title} itemProp="headline" property="og:title" />
			<meta content={description} itemProp="description" property="og:description" />

			{/* <!-- META FOR EXTRA INFORMATION --> */}
			<meta content="news,business,co-operate" itemProp="genre" name="medium" />
			<meta content="vi-VN" itemProp="inLanguage" />
			<meta content="" itemProp="articleSection" />
			<meta content={BASE_CONFIG.SLOGAN} itemProp="sourceOrganization" name="source" />
			<meta name="copyright" content={BASE_CONFIG.PAGE_NAME} />
			<meta name="author" content={BASE_CONFIG.PAGE_NAME} />
			<meta name="robots" content="index,follow" />
			<meta name="geo.placename" content="Ho Chi Minh City, Viet Nam" />
			<meta name="geo.region" content="VN-HCM" />
			<meta name="geo.position" content="10.7617871;106.6935881" />
			<meta name="ICBM" content="10.7617871,106.6935881" />
			<meta name="revisit-after" content="days" />

			{/* <!-- Twitter Card --> */}
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:url" content={url} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={thumbnailUrl} />

			{/*canonical url*/}
			<link rel='canonical' href={url}/>
			{/*publish date*/}
			<meta name="publish_date" property="og:publish_date" content={publishDate} />
			{/*redirect*/}
			{/* <meta httpEquiv="refresh" content={'3; URL='+ redirect} /> */}

		</Head>
	)
}
