export const VALIDATE = {
	CELL_PHONE:
		/^(((\+|)84|0)(86|96|52|97|98|87|32|33|34|35|36|37|38|39|89|90|93|70|79|77|76|78|88|91|94|83|84|85|81|82|92|56|58|99|59|55|69))[0-9]{7}$/,
	TELEPHONE:
		/^(\+?84|0)((((28|24|77)[0-9])|(290|258|208|274|291|299|293|297|292|559|296|270|294|275|277|273|272|254|251|276|271|252|263|259|261|262|257|269|256|260|255|235|236|234|233|232|239|238|237|229|228|227|226|221|225|220|222|211|210|218|212|215|213|214|207|216|209|219|203|204|205|206))[0-9]{7})$/,
}
export const ERROR_MESSAGE = {
	FORMAT: 'Vui lòng nhập đúng định dạng!',
	PHONE_REQUIRE: 'Vui lòng nhập số điện thoại!',
	EMAIL_REQUIRE: 'Vui lòng nhập email!',
	EMAIL_FORMAT: 'Vui lòng đúng định dạng email!',
	ERROR: 'Có lỗi xảy ra, vui lòng thử lại sau!',
	NOT_FOUND_LEAD_CODE: 'Không có mã khách hàng lead!',
	NOT_ENOUGH_INFORTMATION: 'Vui lòng nhập thông tin!',
	MAX_100_CHARACTER: 'Vui lòng nhập tối đa 100 ký tự!',
	MAX_125_CHARACTER: 'Vui lòng nhập tối đa 125 ký tự!',
	MAX_50_CHARACTER: 'Vui lòng nhập tối đa 50 ký tự!',
	MAX_255_CHARACTER: 'Vui lòng nhập tối đa 255 ký tự!',
	OVER_31_DAYS: 'Vui lòng chọn khoảng thời gian nhỏ hơn 31 ngày!',
	NOT_HAVE_ROLE_WITH_LEAD: 'Không thể gửi báo giá với lead của người khác!',
}
export const RULE_FORM = {
	PHONE_NUMBER: [
		{ required: true, message: ERROR_MESSAGE.NOT_ENOUGH_INFORTMATION },
		{
			validator: async (_, value) => {
				if (value) {
					if (VALIDATE.CELL_PHONE.test(value)) {
						return Promise.resolve()
					} else {
						if (VALIDATE.TELEPHONE.test(value)) {
							return Promise.resolve()
						} else {
							return Promise.reject(new Error(ERROR_MESSAGE.FORMAT))
						}
					}
				}
			},
		},
	],
}
