import Footer from 'src/components/Footer'
import Header from 'src/components/Header'

export function MainLayout({ children, breadcrumb }) {
	return (
		<>
			<Header />
			<div className="body-layout">
				{breadcrumb}
				<div>{children}</div>
			</div>
			<Footer />
		</>
	)
}
