import { Button, Col, Form, Input, notification, Row } from 'antd'
import { useState } from 'react'
import contactService from 'src/services/contact.service'
import { RULE_FORM } from 'src/utils/formValidation'
import { validateEmail } from 'src/utils/validation'
const { TextArea } = Input

const ContactForm = () => {
	const [loading, setLoading] = useState(false)
	const [form] = Form.useForm()

	const handleSubmit = async (values) => {
		setLoading(true)
		try {
			const response = contactService.insertContact(values)
			if (!response.IsError) {
				notification.success({
					message: 'Cảm ơn bạn đã liên hệ!',
					description: 'Chúng tôi sẽ phản hồi sớm nhất có thể',
				})
				form.resetFields()
			}
		} catch (error) {
			console.log(error)
		}
		setLoading(false)
	}

	return (
		<div className="contact__form">
			<h3>Liên hệ với chúng tôi</h3>
			<Form form={form} name="home-contact-form" layout="vertical" onFinish={handleSubmit}>
				<Row gutter={24} wrap>
					<Col span={24}>
						<Row gutter={12}>
							<Col xl={12} lg={12} md={12} sm={24} xs={24}>
								<Form.Item
									rules={[
										{ required: true, message: 'Vui lòng nhập tên' },
										{ whitespace: true, message: 'Vui lòng nhập tên' },
										{ max: 200, message: 'Vui lòng nhập tối đa 200 ký tự' },
									]}
									style={{ marginTop: 12 }}
									name="name"
								>
									<Input placeholder="Họ và Tên" />
								</Form.Item>
							</Col>
							<Col xl={12} lg={12} md={12} sm={24} xs={24}>
								<Form.Item rules={RULE_FORM.PHONE_NUMBER} style={{ marginTop: 12 }} name="phone">
									<Input placeholder="Số điện thoại" />
								</Form.Item>
							</Col>
						</Row>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								() => ({
									validator(_, value) {
										if (!value || value == '' || value.trim() == '')
											return Promise.reject(new Error('Vui lòng nhập Email'))

										if (validateEmail(value)) {
											return Promise.resolve()
										}
										return Promise.reject(new Error('Email không đúng định dạng!'))
									},
								}),
							]}
							style={{ marginTop: 12 }}
							name="email"
						>
							<Input placeholder="Email" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							rules={[
								{ min: 10, message: 'Nội dung phải lớn hơn 10 ký tự!' },
								{ required: true, message: 'Vui lòng nhập nội dung' },
								{ max: 500, message: 'Vui lòng nhập tối đa 500 ký tự' },
							]}
							style={{ marginTop: 12 }}
							name="content"
						>
							<TextArea rows={4} placeholder="Nội dung" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<div className="send-btn__wrapper">
							<Button loading={loading} htmlType="submit" type="primary">
								Gửi
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</div>
	)
}

export default ContactForm
