const { useEffect, useState } = require('react')
const { default: addressServices } = require('src/services/address.service')

// Lấy danh sách phường/xã từ ID huyện

const useWards = (districtID) => {
	const [wards, setWards] = useState(null)

	// get from ward
	useEffect(() => {
		if (!districtID) return
		addressServices
			.queryWards(districtID)
			.then((response) => {
				if (!response.isError) setWards(response.wards)
			})
			.catch(console.log)
	}, [districtID])

	return wards
}

export default useWards
