import { RightOutlined } from '@ant-design/icons'
import { Button, Divider, Modal } from 'antd'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import { NotifIcon, PasswordIcon, RankIcon, SmsIcon, UserIcon } from 'src/components/CustomIcon'
import { GlobalContext } from 'src/pages/_app'
import NotifForm from './NotifForm'
import PasswordForm from './PasswordForm'

const UserOptionItems = [
	{
		_id: 'thongtintaikhoan',
		title: 'Thông tin tài khoản',
		icon: <UserIcon />,
		val: 1,
	},
	{
		_id: 'smslog',
		title: 'SMS Log',
		icon: <SmsIcon />,
		val: 2,
	},
	// {
	// 	_id: 'caidatthongbao',
	// 	title: 'Cài đặt thông báo',
	// 	icon: <NotifIcon />,
	// 	val: 3,
	// },
	{
		_id: 'thaydoimatkhau',
		title: 'Thay đổi mật khẩu',
		icon: <PasswordIcon />,
		val: 4,
	},
]

const UserOptions = () => {
	const router = useRouter()
	const { user, getUser } = useContext(GlobalContext)
	const [contentKey, setContentKey] = useState(0)

	const modalTitle = useMemo(
		() => UserOptionItems.find((e) => e.val === contentKey)?.title || '',
		[contentKey]
	)

	const handleSelectMenu = (val) => {
		if (val == 1) {
			router.push('/khach-hang/ho-so-ca-nhan')
		} else if (val == 2) {
			router.push('/khach-hang/sms-log')
		} else {
			setContentKey(val)
		}
	}

	const handleCloseModal = () => {
		setContentKey(0)
	}

	const handleLogout = () => {
		getUser(null)
		localStorage.removeItem('user')
		router.push('/')
	}

	useEffect(() => {
		return () => {
			setContentKey(0)
		}
	}, [])

	return (
		<div className="user__options">
			<Modal
				getContainer=".user__options"
				title={<div style={{ textAlign: 'center', fontSize: 20, padding: 4 }}>{modalTitle}</div>}
				open={contentKey}
				footer={null}
				onCancel={handleCloseModal}
				centered
			>
				{contentKey === 3 && <NotifForm />}
				{contentKey === 4 && <PasswordForm />}
			</Modal>
			<div className="user__options-container">
				<div className="user__options-info">
					<div className="info-avatar">
						{/* eslint-disable-next-line @next/next/no-img-element */}
						<img src={user.avatarUrl ? user.avatarUrl : "/images/default_avatar.jpeg"} alt="User Option" />
					</div>
					<div className="info-content">
						<span className="info-name">{user.displayName}</span>
						<span className="info-role">Khách hàng</span>
						{/* <div className="info-rank">
							<RankIcon /> <span className="rank-text">Diamond</span>
						</div> */}
					</div>
				</div>
				<Divider />
				<div className="user__options-menu">
					<ul>
						{UserOptionItems.map((e) => (
							<li key={e._id} onClick={() => handleSelectMenu(e.val)}>
								<div className="menu-icon">{e.icon}</div>
								<span className="menu-title">{e.title}</span>
								<RightOutlined />
							</li>
						))}
						<Button onClick={handleLogout} type="primary" style={{ height: 44 }} block>
							Đăng xuất
						</Button>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default UserOptions
