import { PMS_APIs } from 'src/configs/API'
import { toCapitalCase } from 'src/utils/common'
import request from 'src/utils/request'
import useSWR from 'swr'

const queryDistricts = (provinceID) => {
	return request.pmsApi({
		url: PMS_APIs.API_GET_DISTRICTS_BY_PROVINCEID,
		method: 'POST',
		data: {
			ID: provinceID,
		},
	})
}

const queryCities = async () => {
	return request.pmsApi({
		url: PMS_APIs.API_GET_PROVINCES,
		data: {
			ID: 'VN',
		},
		method: 'POST',
	})
}
const queryCitiesByCountryID = async (countryID) => {
	return request.pmsApi({
		url: PMS_APIs.API_GET_PROVINCES,
		data: {
			ID: countryID,
		},
		method: 'POST',
	})
}

const queryCountry = async () => {
	return request.pmsApi({
		url: PMS_APIs.API_GET_COUNTRIES,
		data: {},
		method: 'POST',
	})
}

const queryWards = (districtID) => {
	return request.pmsApi({
		url: PMS_APIs.API_GET_WARDS_BY_DISTRICTID,
		method: 'POST',
		data: {
			ID: districtID,
		},
	})
}

const usePMSCities = () => {
	const { data, error } = useSWR(`PMS_Cities`, queryCities, {
		dedupingInterval: 1200000,
	})

	const capitalCities =
		data?.provinces
			?.filter((e) => e.isActive && e.provinceID !== 'VN')
			.map((e) => ({ ...e, provinceName: toCapitalCase(e.provinceName) })) || []

	return {
		cities: capitalCities,
		isLoading: !error && !data,
		isError: error,
	}
}

const usePMSDistricts = (provinceID) => {
	const { data, error } = useSWR(
		provinceID ? `PMS_Districts_${provinceID}` : null,
		() => queryDistricts(provinceID),
		{
			dedupingInterval: 1200000,
		}
	)

	return {
		districts: data?.districts,
		isLoading: !error && !data,
		isError: error,
	}
}

const usePMSCountry = () => {
	const { data, error } = useSWR('PMS_Country', () => queryCountry(), {
		dedupingInterval: 1200000,
	})

	return {
		countries: data?.countries,
		isLoading: !error && !data,
		isError: error,
	}
}

const addressServices = {
	queryDistricts,
	queryWards,
	usePMSCities,
	usePMSDistricts,
	queryCountry,
	queryCitiesByCountryID,
	usePMSCountry,
}

export default addressServices
