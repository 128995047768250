import APIConstants, { PMS_APIs, V4_APIs } from 'src/configs/API'
import request from 'src/utils/request'

const calcPrice = (payload) => {
	return request.pmsApi({
		url: PMS_APIs.API_CALC_PRICE,
		method: 'POST',
		data: {
			FromProvinceID: payload.fromProvinceId,
			FromDistrictID: payload.fromDistrictId,
			FromWardID: payload.fromWardId,
			ToProvinceID: payload.toProvinceId,
			ToDistrictID: payload.toDistrictId,
			ToWardID: payload.toWardId,
			Weight: payload.weight,
			ServiceIDs: payload.services,
			IsReturn: false,
			AcceptTime: payload.acceptTime,
			Length: payload.length,
			Width: payload.width,
			Height: payload.height,
			Quantity: payload.quantity,
		},
	})
}

const calcPriceInternational = (payload) => {
	return request.pmsApi({
		url: PMS_APIs.API_CALC_PRICE_INTERNATIONAL,
		method: 'POST',
		data: payload,
	})
}

const getCards = () => {
	return request.v4Api({
		url: APIConstants.API_GET_HOME_CARDS,
		method: 'POST',
		data: {
			Status: 2,
		},
	})
}

const getStatistics = () => {
	return request.v4Api({
		url: V4_APIs.GET_STATISTICS,
	})
}

const getImage = (name) => {
	return request.v4Api({
		url: V4_APIs.API_GET_BANNER + name,
	})
}

const commonServices = {
	calcPrice,
	getCards,
	getStatistics,
	getImage,
	calcPriceInternational,
}

export default commonServices
