import { Button, Form, Input } from 'antd'
import { useRouter } from 'next/router'
import { handleURI } from 'src/utils/common'
const TrackingTab = () => {
	const router = useRouter()
	const [form] = Form.useForm()

	const handleSubmit = (values) => {
		router.push({
			pathname: `/tra-cuu-van-don/${handleURI(values.code)}`,
		})
	}

	return (
		<div className="banner__tab tracking__tab">
			<Form form={form} name="tracking" onFinish={handleSubmit} onFinishFailed={console.log}>
				<Form.Item
					style={{ marginTop: 12 }}
					name="code"
					rules={[{ required: true, message: 'Nhập mã vận đơn' }]}
				>
					<Input
						placeholder="Nhập mã vận đơn"
						onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
						id="order-code-input"
					/>
				</Form.Item>
				<p className="tracking__tab-description">
					{
						'Mã vận đơn/ mã đơn hàng là mã vạch theo dõi trên đơn hàng, chứng từ gửi qua 247Express. Bạn có thể tra cứu một hoặc nhiều mã vận đơn theo ví dụ sau: 90027500011, 90027500012'
					}
				</p>
				<div className="tracking__tab-btn__wrapper">
					<Button htmlType="submit" type="primary">
						Theo dõi
					</Button>
				</div>
			</Form>
		</div>
	)
}

export default TrackingTab
