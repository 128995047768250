const { default: APIConstants } = require('src/configs/API')
const { default: request } = require('src/utils/request')

const insertContact = ({ name, phone, email, content }) => {
	return request.v4Api({
		url: APIConstants.API_INSERT_CONTACT,
		data: {
			Name: name,
			Status: false,
			Phone: phone,
			Email: email,
			Content: content,
		},
		method: 'POST',
	})
}
const insertContactPushtoBiz = ({ name, phone, email, content }) => {
	return request.v4Api({
		url: APIConstants.API_INSERT_CONTACT_PUSH_TO_BIZ,
		data: {
			Name: name,
			Status: false,
			Phone: phone,
			Email: email,
			Content: '',
			Address: content,
		},
		method: 'POST',
	})
}

const insertPriceContact = (value) => {
	return request.v4Api({
		url: APIConstants.API_INSERT_PRICE_CONTACT,
		data: value,
		method: 'POST',
	})
}

const contactServices = {
	insertContact,
	insertPriceContact,
	insertContactPushtoBiz,
}

export default contactServices
