import { PMS_APIs, V4_APIs } from 'src/configs/API'
import request from 'src/utils/request'

const getExtraServices = async () => {
	return request.pmsApi({
		url: PMS_APIs.API_GET_HOME_EXTRA_SERVICE,
		data: {
			ApiKey: 'PMS',
			ApiSecretKey: 'PMS',
		},
	})
}

const getExtraServiceList = async () => {
	return request.v4Api({
		url: V4_APIs.GET_EXTRA_SERVICES,
	})
}
const getPackServiceList = async () => {
	return request.v4Api({
		url: V4_APIs.GET_PACK_SERVICES,
	})
}

const extraServiceServices = {
	getExtraServices,
	getExtraServiceList,
	getPackServiceList,
}

export default extraServiceServices
